import styled from "styled-components";

const LanguageSwitcherWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  .flag {
    width: 2.5rem;
    height: 2.5rem;
    object-fit: contain;
    cursor: pointer;
  }
`;

const MenuIconWrapper = styled.div`
  display: flex;
  align-items: center;
  font-weight: 500;

  img {
    width: 2.5rem;
    height: 2.5rem;
    object-fit: contain;
    margin-right: 1rem;
    border-radius: 3px;
  }
`;

export { LanguageSwitcherWrapper, MenuIconWrapper };
