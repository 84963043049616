import { useEffect, useMemo } from "react";
import ListUserImage from "./ListUserImage";
import { ImageProcessingWrapper } from "./style";
import { useLocation } from "react-router-dom";
import paymentApi from "../../api/payment";
import { useTranslation } from "../../hook/useTranslation";

const ImageProcessing = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const statusPayment = searchParams.get("status");

  const sendDataToSheet = async (userInfomation: any) => {
    const userInfoArr = Object.values(userInfomation);

    // send data to sheet
    const res = await paymentApi.uploadDataToSheet({ data: userInfoArr });

    return res;
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        let userInfomation = JSON.parse(
          localStorage.getItem("user_info") as string
        );

        if (userInfomation) {
          userInfomation = {
            ...userInfomation,
            user_id: localStorage.getItem("user_id"),
          };

          const resSendDataToSheet = await sendDataToSheet(userInfomation);

          await paymentApi.messageTelegram({ data: userInfomation });

          // send data successfully
          if (resSendDataToSheet?.status === 200) {
            localStorage.removeItem("user_info");
          } else {
            window.location.reload();
          }
        }
      } catch (error) {
        // refresh current page
        window.location.reload();
      }
    };

    fetchData();
  }, []);

  const { translate } = useTranslation();

  return (
    <ImageProcessingWrapper>
      <div className="banner">
        {/* <img src="./img/payment_successfully.png" /> */}
        <img src={"./img/payment_successfully.png"} />
      </div>

      <div className="title">Thank you</div>

      {true ? (
        <div className="description">
          {translate("thank1")} <span style={{ fontWeight: "bold" }}>24h</span>{" "}
          {translate("thank2")}{" "}
          <span style={{ fontWeight: "bold" }}>Email</span> and{" "}
          <span style={{ fontWeight: "bold" }}>Zalo</span> của bạn.
        </div>
      ) : (
        <div className="description">{translate("error")}</div>
      )}

      <div className="bottom">
        <a href="/" className="go-home">
          {translate("backHome")}
        </a>
      </div>

      <ListUserImage />
    </ImageProcessingWrapper>
  );
};

export default ImageProcessing;
