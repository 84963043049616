import { Alert, Button, Form, Input, Modal, Tooltip, message } from "antd";
import { useEffect, useMemo, useState } from "react";
import paymentApi from "../../api/payment";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "../../hook/useTranslation";
import PlusIcon from "../../components/icon/PlusIcon";
import "../../components/UploadFile/style.css";
import axios from "axios";
import { BACKEND_ENDPOINT } from "../../common/environment";

const AMOUNT_ON_EACH_IMAGE = 15000;

let timeoutId: any;

export default function ContactMe() {
  const { translate } = useTranslation();
  const navigate = useNavigate();

  const [form] = Form.useForm();
  const [messageApi, contextHolder] = message.useMessage();

  const [userVoucher, setUserVoucher] = useState<any>(0);
  const [isNotFoundVoucher, setIsNotFoundVoucher] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);

  const [selectedFiles, setSelectedFiles] = useState<any>([]);

  const totalAmount = useMemo(() => {
    const currentTotalAmount = selectedFiles.length * AMOUNT_ON_EACH_IMAGE;
    const discount = currentTotalAmount * (userVoucher / 100);
    return currentTotalAmount;
  }, [selectedFiles.length, userVoucher]);

  const amount = useMemo(() => {
    return selectedFiles.length * AMOUNT_ON_EACH_IMAGE;
  }, [selectedFiles.length, userVoucher]);

  const [phone, setPhone] = useState("");

  useEffect(() => {
    form.setFieldValue("amount", totalAmount);
  }, [totalAmount]);

  const user_id = localStorage.getItem("user_id");

  // auto fill infomation
  // useEffect(() => {
  //   form.setFieldsValue({
  //     username: "Trần Văn Sự",
  //     email: "tranvansu@gmail.com",
  //     phone: "0987654321",
  //   });
  // }, []);

  const getCurrentDateTime = () => {
    const currentTime = new Date();
    const hour = currentTime.getHours().toString().padStart(2, "0");
    const minute = currentTime.getMinutes().toString().padStart(2, "0");
    const date = currentTime.getDate().toString().padStart(2, "0");
    const month = (currentTime.getMonth() + 1).toString().padStart(2, "0");
    const dateTimeString = `${hour}:${minute}_${date}/${month}`;

    return dateTimeString;
  };

  const convertToSlug = (inputString: string) => {
    return inputString
      .toLowerCase()
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join("-");
  };

  const [isShowQr, setIsShowQr] = useState(false);

  const handleQROk = async () => {
    setLoading(true);
    let userInfomation;

    const { username, email, phone, voucher } = form.getFieldsValue([
      "username",
      "email",
      "phone",
      "voucher",
    ]);

    // INIT USER VALUE
    userInfomation = {
      username,
      email,
      phone,
      voucher: userVoucher || "no_voucher",
      amount: totalAmount,
      listImageId: "",
    };

    const convertedUserName = convertToSlug(username);

    const formData = new FormData();
    selectedFiles.forEach((file: any, index: any) => {
      formData.append(`images`, file);
    });

    const currentTime = getCurrentDateTime();
    formData.append("username", `${currentTime}-${convertedUserName}`);

    try {
      const response = await axios.post(
        `${BACKEND_ENDPOINT}/upload`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      // upload image successfully
      if (response.status === 200) {
        const listImageId = response.data?.fileIds;

        const listImageLink = listImageId.map((id: any) => id);

        userInfomation = {
          ...userInfomation,
          currentTime,
          isUpload: true,
          listImageId: listImageLink.join(","),
        };

        if (totalAmount === 0) {
          userInfomation = {
            ...userInfomation,
            amount: 0,
            isUpload: "done",
          };
        }
        localStorage.setItem("user_info", JSON.stringify(userInfomation));
        setLoading(false);

        setTimeout(() => {
          window.location.href = "/image-processing";
        }, 500);
      }
    } catch (error) {
      userInfomation = {
        ...userInfomation,
        amount: 0,
        isUpload: "not",
      };

      localStorage.setItem("user_info", JSON.stringify(userInfomation));
    }
  };

  const handleQRClose = () => {
    setIsShowQr(false);
  };

  const handleCopy = () => {};

  // SUBMIT DATA
  const onFinish = async (values: any) => {
    if (selectedFiles.length === 0) {
      messageApi.error("Please select at least 1 image");

      return;
    }

    let userInfomation;
    // setLoading(true);
    setIsShowQr(true);
  };

  const warningPayment = () => {
    messageApi.open({
      type: "warning",
      content: "Bạn sẽ được chuyển đến trang thanh toán trong vài giây nữa",
    });
  };

  const onFinishFailed = (errorInfo: any) => {};

  const handleOnChangeVoucher = async (e: any) => {
    clearTimeout(timeoutId);

    timeoutId = setTimeout(async () => {
      const value = e.target.value;

      if (!value) {
        setUserVoucher(0);
        return;
      }

      try {
        const { status, voucherValue } = await paymentApi.getVoucher({
          voucher: value,
        });

        if (status === 200) {
          setIsNotFoundVoucher(voucherValue === null);
          setUserVoucher(voucherValue ? parseFloat(voucherValue) : 0);
        }
      } catch (error) {
        console.error("Error fetching voucher:", error);
      }
    }, 300);
  };

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [previewImageSrc, setPreviewImageSrc] = useState<string>("");

  const handleFileChange = (event: any) => {
    if (selectedFiles.length < 5) {
      const files = Array.from(event.target.files).slice(0, 5);
      setSelectedFiles((prevFiles: any) => [...prevFiles, ...files]);
    } else {
      messageApi.error("You can only upload 5 images at a time");
    }
  };

  const handleRemoveFile = (index: any) => {
    setSelectedFiles((prevFiles: any) => [
      ...prevFiles.slice(0, index),
      ...prevFiles.slice(index + 1),
    ]);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const preViewImage = (src: string) => {
    setIsModalOpen(true);
    setPreviewImageSrc(src);
  };

  const BoldText = (props: any) => {
    const { text } = props;
    return <span style={{ fontWeight: "bold" }}>{text}</span>;
  };

  return (
    <section id="Contact" className="contact--section">
      {contextHolder}

      <div>
        <h2>{translate("contactUs")}</h2>
        <p className="text-lg">
          {translate("aboutDesc")}
          <span style={{ fontWeight: "bold" }}>Zalo </span>
          {translate("and")} <span style={{ fontWeight: "bold" }}>Email.</span>{" "}
        </p>
      </div>

      <div className="commit">
        <span
          className="commit"
          style={{
            marginBottom: "3px",
            border: "1px dashed #1744d2",
            padding: "10px 5px",
            display: "block",
            fontSize: "14px",
          }}
        >
          <span style={{ color: "red" }}>*</span> {translate("commit")}
        </span>
      </div>
      <Form
        name="basic"
        // labelCol={{ span: 8 }}
        // wrapperCol={{ span: 16 }}
        style={{
          // width: "600px",
          width: "376px",
        }}
        className="form-contact"
        initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        form={form}
      >
        <Form.Item
          label={translate("username")}
          name="username"
          rules={[{ required: true, message: translate("inputUserName") }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="email"
          label="Email"
          rules={[{ required: true, message: translate("inputEmail") }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="phone"
          label={translate("phoneNumber")}
          rules={[{ required: true, message: translate("inputPhoneNumber") }]}
        >
          <Input
            style={{ width: "100%" }}
            onChange={(e) => setPhone(e.target.value)}
          />
        </Form.Item>

        <Form.Item
          label="Voucher"
          name="voucher"
          style={{ marginBottom: userVoucher === null ? "0px" : "24px" }}
        >
          <Input onChange={handleOnChangeVoucher} />
          <div
            style={{
              color: "red",
              fontSize: "11px",
              display: isNotFoundVoucher ? "block" : "none",
            }}
          >
            {isNotFoundVoucher && translate("voucherNotFound")}
          </div>
        </Form.Item>

        <Alert
          message={translate("price")}
          type="info"
          showIcon
          style={{ marginBottom: "3px" }}
        />

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "20px",
            marginTop: "20px",
          }}
        >
          <span>{translate("amount")}</span>
          <span className="amount-position">{amount}</span>
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "20px",
          }}
        >
          <span>Voucher</span>
          <span
            className="amount-position discount"
            style={{ marginLeft: "auto" }}
          >
            {userVoucher > 0 && "-"}
            {amount * (userVoucher / 100)}
          </span>
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "20px",
          }}
        >
          <span>{translate("paymentAmount")}</span>
          <span className="amount-position">{totalAmount} </span>
        </div>

        <Form.Item
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <div className="upload-wrapper">
            <label htmlFor="upload-input" className="input-label">
              <PlusIcon />
              <span>{translate("upload")}</span>
            </label>
            <input
              id="upload-input"
              type="file"
              multiple
              accept="image/*"
              onChange={handleFileChange}
              style={{ display: "none" }}
            />
          </div>

          <div className="selected-files">
            {selectedFiles.map((file: any, index: any) => (
              <div
                key={index}
                className="file-item"
                style={{ marginTop: "20px" }}
              >
                <Tooltip
                  placement="top"
                  title="Click to preview"
                  className="tool-tip"
                >
                  <img
                    src={URL.createObjectURL(file)}
                    alt={`Preview ${index}`}
                    onClick={() => preViewImage(URL.createObjectURL(file))}
                    style={{ border: "1px dashed #6f9edf" }}
                  />
                </Tooltip>
                <span>{file?.name}</span>
                <button onClick={() => handleRemoveFile(index)}>
                  {translate("remove")}
                </button>
              </div>
            ))}
          </div>

          <Modal
            title={translate("preview")}
            open={isModalOpen}
            onOk={handleOk}
            onCancel={handleCancel}
          >
            <img src={previewImageSrc} className="preview-image" />
          </Modal>

          <Modal
            title="QR code"
            open={isShowQr}
            onOk={handleQROk}
            onCancel={handleQRClose}
            okText="Gửi thông tin"
            cancelText="Đóng"
            confirmLoading={loading}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <img
                src="./img/QR.jpg"
                style={{
                  height: "400px",
                  width: "450px",
                }}
              />
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "10px",
                fontWeight: "bold",
              }}
            >
              Quét mã QR để thanh toán
            </div>

            <span>Hoặc thanh toán qua tài khoản BIDV:</span>
            <div>
              <span>
                Số tài khoản: <BoldText text="4270788731" />
              </span>
            </div>
            <div>
              <span>
                Chủ tài khoản: <BoldText text="TRAN VAN SU" />
              </span>
            </div>
            <div>
              <span>
                Chi nhánh: <BoldText text="QUANG MINH" />
              </span>
            </div>

            <div
              style={{
                backgroundColor: "#bda7a7",
                height: "1.5px",
                width: "100%",
                margin: "10px 0",
              }}
            ></div>

            <div style={{ marginTop: "5px" }}>Nội dung chuyển khoản:</div>
            <div>
              <Alert
                type="info"
                message={`SHARPIFY AI ${phone}`}
                style={{
                  fontWeight: "bold",
                  textAlign: "center",
                  marginTop: "5px",
                }}
              />
            </div>

            <div style={{ marginTop: "5px" }}>
              Số tiền: <BoldText text={`${totalAmount} VND`} />
            </div>

            <div style={{ marginTop: "5px" }}>
              Sau khi gửi xong hãy ấn nút: <BoldText text="Gửi thông tin" /> bên
              dưới
            </div>
          </Modal>
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit" loading={loading}>
            {translate("submit")}{" "}
          </Button>
        </Form.Item>
      </Form>
    </section>
  );
}
