import { ErrorPageWrapper } from "./style";

const ErrorPage = () => {
  return (
    <ErrorPageWrapper>
      <div className="banner">
        <img src="./img/error.jpg" />
      </div>

      <div className="title">
        Hệ thống của chúng tôi đang có lỗi. Hãy thử lại sau.
      </div>
      <div className="bottom">
        <a href="/" className="go-home">
          Về trang chủ
        </a>
      </div>
    </ErrorPageWrapper>
  );
};

export default ErrorPage;
