import { BACKEND_ENDPOINT } from "../common/environment";
import axios from "axios";

const API_TIMEOUT = 10000;
const BASE_ENDPOINT = BACKEND_ENDPOINT;

const api = async (
  method = "GET",
  url: string,
  requestBody: any,
  params?: any
) => {
  try {
    const res = await axios({
      method,
      url: BASE_ENDPOINT + url,
      data: requestBody,
      params,
      timeout: API_TIMEOUT,
    });

    return res?.data;
  } catch (error: any) {
    return { error: error?.response?.data?.message };
  }
};

export default api;
