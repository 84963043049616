import api from "./common";
import { API_METHOD } from "../common/constant";

const paymentApi = {
  createOrder: (body: any) =>
    api(API_METHOD.POST, "/payment/create-order", body),
  uploadImage: (body: any) => api(API_METHOD.POST, "/upload", body),
  uploadDataToSheet: (body: any) =>
    api(API_METHOD.POST, "/push-data-to-sheet", body),
  messageTelegram: (body: any) => api(API_METHOD.POST, "/send-telegram", body),
  getVoucher: (body: any) =>
    api(API_METHOD.GET, `/get-voucher/${body.voucher}`, body),
  getUserId: (body: any) => api(API_METHOD.GET, `/user-id`, body),
};

export default paymentApi;
