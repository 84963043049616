import styled from "styled-components";

const ImageProcessingWrapper = styled.div`
  min-height: 50vh;
  min-width: 50vw;
  margin-top: 7%;

  .banner {
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      margin: 0 auto;
      height: 400px;
      width: 500px;
    }
  }

  .title {
    font-size: 28px;
    font-weight: bold;
    text-align: center;
    margin-top: 20px;
  }

  .description {
    margin-top: 10px;
    font-size: 18px;
    text-align: center;
  }

  .bottom {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .go-home {
    width: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    color: #fff;
    background-color: #007bff;
    padding: 10px 20px;
    border-radius: 5px;
    text-decoration: none;
  }
`;

export { ImageProcessingWrapper };
