import { useTranslation } from "../../hook/useTranslation";

export default function AboutMe() {
  const { translate } = useTranslation();

  return (
    <section id="AboutMe" className="about--section">
      <div className="about--section--img">
        <img src="./img/longvu.png" alt="About Me" className="image-desc" />
      </div>
      <div className="hero--section--content--box about--section--box">
        <div className="hero--section--content">
          <h1 className="skills-section--heading">{translate("aboutUs1")}</h1>
          <p className="hero--section-description">{translate("aboutUs")}</p>
        </div>
      </div>
    </section>
  );
}
