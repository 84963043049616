import { useContext, useEffect } from "react";
import { Dropdown, MenuProps } from "antd";
import { useTranslation } from "../../hook/useTranslation";
import { LOCALE, LanguageContext } from "../../language/index";
const vnFlag = require("../../asset/vietnam.png");
const enFlag = require("../../asset/en.png");
import { LanguageSwitcherWrapper, MenuIconWrapper } from "./style";

const LanguageSwitch = () => {
  const { onChangeLocale } = useContext(LanguageContext);
  const { translate, locale } = useTranslation();

  useEffect(() => {
    const cacheItem = localStorage.getItem("locale");
    if (cacheItem) {
      onChangeLocale(cacheItem);
    }
  }, []);

  const dropdownConfig: MenuProps["items"] = [
    {
      key: LOCALE.VI,
      label: (
        <MenuIconWrapper onClick={() => onChangeLocale(LOCALE.VI)}>
          <img src={vnFlag} alt="vi" className="language-flag" />
          {translate("vietnamese")}
        </MenuIconWrapper>
      ),
    },
    {
      key: LOCALE.EN,
      label: (
        <MenuIconWrapper onClick={() => onChangeLocale(LOCALE.EN)}>
          <img src={enFlag} alt="en" className="language-flag" />
          {translate("english")}
        </MenuIconWrapper>
      ),
    },
  ];

  return (
    <LanguageSwitcherWrapper>
      <Dropdown menu={{ items: dropdownConfig }} placement="topRight">
        <img
          src={locale === LOCALE.VI ? vnFlag : enFlag}
          alt="flag"
          className="flag"
        />
      </Dropdown>
    </LanguageSwitcherWrapper>
  );
};

export default LanguageSwitch;
