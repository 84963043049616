import "./App.scss";
import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Navbar from "./Pages/Home/Navbar";
import Home from "./Pages/Home/Homescreen";
import { Provider } from "react-redux";
import store from "./redux/store";
import ImageProcessing from "./Pages/ProcessingImage";
import { LOCALE, LanguageContext, listLocale } from "./language";
import ErrorPage from "./Pages/ErrorPage";
import paymentApi from "./api/payment";

function App() {
  const [locale, setLocale] = useState(LOCALE.VI);

  const contextProvider = {
    locale,
    onChangeLocale: (selected: string) => {
      const newLocale = listLocale[selected] ? selected : LOCALE.VI;
      setLocale(newLocale);
      localStorage.setItem("locale", newLocale);
    },
  };

  const user_id = localStorage.getItem("user_id");

  useEffect(() => {
    const getUserId = async () => {
      if (!user_id) {
        const res = await paymentApi.getUserId({});
        const { userId } = res;
        localStorage.setItem("user_id", userId);
      }
    };

    getUserId();
  }, []);

  return (
    <Provider store={store}>
      <LanguageContext.Provider value={contextProvider}>
        <div className="App">
          <Router>
            <div>
              <Navbar />
              <Routes>
                <Route path="/" element={<Home />}></Route>
                <Route path="*" element={<div>404 Not Found</div>}></Route>
                <Route
                  path="/image-processing"
                  element={<ImageProcessing />}
                ></Route>
                <Route path="/error" element={<ErrorPage />}></Route>
              </Routes>
            </div>
          </Router>
        </div>
      </LanguageContext.Provider>
    </Provider>
  );
}

export default App;
