import { useTranslation } from "../../hook/useTranslation";
import { useMemo } from "react";
import {
  ReactCompareSlider,
  ReactCompareSliderImage,
} from "react-compare-slider";
import { TypeAnimation } from "react-type-animation";

export default function HeroSection() {
  const locale = localStorage.getItem("locale");

  const isVi = useMemo(() => {
    return locale === "vi";
  }, [locale]);

  const { translate } = useTranslation();

  const onPositionChange = (position: any) => {
    const beforeElement = document.getElementsByClassName("before")[0];
    const afterElement = document.getElementsByClassName("after")[0];

    if (position < 20) {
      beforeElement.innerHTML = "";
    } else if (position > 85) {
      afterElement.innerHTML = "";
    } else {
      beforeElement.innerHTML = isVi ? "Trước" : "Before";

      afterElement.innerHTML = isVi ? "Sau" : "After";
    }
  };

  return (
    <section id="heroSection" className="hero--section">
      <div className="hero--section--content--box">
        <div className="hero--section--content">
          <h1 className="hero--section--title">
            <span className="hero--section-title--color">
              <TypeAnimation
                sequence={[
                  "SHARPIFY",
                  1000,
                  "SHARPIFY AI", //  Continuing previous Text
                  3000,
                  "SHARPIFY",
                  1000,
                  "",
                  1000,
                ]}
                style={{ fontSize: "2rem", color: "#1db0f1" }}
                repeat={Infinity}
                speed={20}
              />
            </span>
            <br />
          </h1>
          <p className="hero--section-description">
            {translate("title1")}
            <br />
            {translate("title2")}
          </p>
        </div>
        <div
          style={{ display: "flex", justifyContent: "center" }}
          className="get-touch"
        >
          <button className="btn btn-primary">Get In Touch</button>
        </div>
      </div>
      <div className="hero--section--img">
        {/* <ImgComparisonSlider>
          <img slot="first" src="./img/blur_img.jpg" className="img-hero" />

          <img
            slot="second"
            src="./img/sharpify_img.jpg"
            className="img-hero"
          />
        </ImgComparisonSlider> */}

        <div className="before">Before</div>
        <div className="after">After</div>
        <ReactCompareSlider
          itemOne={
            <ReactCompareSliderImage
              src="./img/blur_img.jpg"
              srcSet="./img/blur_img.jpg"
              alt="Image one"
            />
          }
          itemTwo={
            <ReactCompareSliderImage
              src="./img/sharpify_img.jpg"
              srcSet="./img/sharpify_img.jpg"
              alt="Image two"
            />
          }
          onPositionChange={onPositionChange}
        />
      </div>
    </section>
  );
}
