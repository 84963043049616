const ListUserImage = () => {
  // const listUserImage = JSON.parse(
  //   sessionStorage.getItem("listUserImage") || ""
  // );

  let listUserImage:any=[]

  return (
    <div>
      {listUserImage.map((item: any, index: number) => (
        <>
          <h2>Image result</h2>
          <img
            src="./img/payment_successfully.png"
            alt="Base64 Image"
            style={{ width: "300px", height: "auto" }}
          />
        </>
      ))}
    </div>
  );
};

export default ListUserImage;
