// src/app/store.ts
import { configureStore } from "@reduxjs/toolkit";
import sharpifyReducer from "./app";

const store = configureStore({
  reducer: {
    sharpify: sharpifyReducer,
    // Add other reducers as needed
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
