// src/features/sharpifySlice.ts
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface SharpifyState {
  listUserImage: [];
  infomation: {};
}

interface SharpifyState {
  listUserImage: [];
  infomation: {};
}

const initialState: SharpifyState = {
  listUserImage: [],
  infomation: {},
};

const sharpifySlice = createSlice({
  name: "sharpify",
  initialState,
  reducers: {
    setAddUserImage: (state, action: PayloadAction<any>) => {
      if (!state.listUserImage.includes(action.payload as never)) {
        /* @ts-ignore */
        state.listUserImage.push(action.payload);
      }

      /* @ts-ignore */
    },
    setRemoveUserImage: (state, action: PayloadAction<any>) => {
      /* @ts-ignore */
      state.listUserImage = [...state.listUserImage, action.payload];
    },
    actSetInfomation: (state, action: PayloadAction<any>) => {
      state.infomation = action.payload;
    },
  },
});

export const { setAddUserImage, actSetInfomation, setRemoveUserImage } =
  sharpifySlice.actions;
export default sharpifySlice.reducer;
